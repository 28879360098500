<template>
    <!-- 预约拍照支付页路由 -->
    <div class="photograph_pay_box">
        <div class="container">
            <!-- 步骤条组件 -->
            <div class="photograph_pay_steps_box">
                <el-steps 
                    :active="order_status" 
                    finish-status="success"
                    class="photograph-pay-steps">
                    <el-step title="核对订单信息"></el-step>
                    <el-step title="已支付"></el-step>
                    <el-step title="等待服务"></el-step>
                    <el-step title="已取消" v-if="order_status==-1"></el-step>
                    <el-step title="已完成" v-else></el-step>
                </el-steps>
            </div>
            <!-- 取消订单提示 -->
            <div class="order-cancel"
                v-if="order_status==-1">
                您已支付的金额我们会在48小时内联系退回，请留意客服电话。
            </div>
            <!-- 组件 -->
            <order-check v-if="order_status==0"></order-check>
            <order-paid v-if="order_status==1"></order-paid>
            <order-serve v-if="order_status==-1 || order_status==2 || order_status==3 || order_status==5 || order_status==9 || order_status==-2"></order-serve>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
export default {
    computed: mapState({
        is_login: state => state.user.is_login,
        order_status: state => state.order.order_status
    }),
    components: {
        orderCheck: () => import('@/components/orderCheck'),
        orderPaid: () => import('@/components/orderPaid'),
        orderServe: () => import('@/components/orderServe')
    },
    methods: {
        ...mapMutations([
            "setPhotograpOrder"
        ]),
        ...mapActions([
            "getLeaseOrderDetail",
            "getPhotograpOrderDetail",
            "getFieldOrdFerDetail"
        ])
    },
    beforeRouteEnter (to, from, next) {
        // ...
        next(vm => {
            if(vm.is_login){
                if(to.name == "clothesPay"){
                    vm.getLeaseOrderDetail(to.query.id)
                }else if (to.name == "photographPay"){
                    vm.getPhotograpOrderDetail(to.query.id)
                }else if(to.name == "fieldPay"){
                    vm.getFieldOrdFerDetail(to.query.id)
                }
            }else{
                vm.$message({
                    showClose: true,
                    message: "您还未登录！",
                    type: "error"
                })
                vm.$router.back();
            }
        })
    },
    beforeRouteLeave (to, from, next) {
        this.setPhotograpOrder({});
        next();
    }
}
</script>


<style scoped>
.photograph_pay_box{
    background-color: #fff;
    margin: 50px 0;
}
/* 步骤条组件盒子 */
.photograph_pay_steps_box{
    padding: 50px 80px;
}
/* 当前步骤样式更改 */
/* .photograph_pay_steps_box>>>.is-process{
    color: #409EFF;
    border-color: #409EFF;
} */
.photograph-pay-steps >>> .el-step__head.is-success,
.photograph-pay-steps >>> .el-step__title.is-success{
    color: #8d9afc;
    border-color: #8d9afc;
}
.order-cancel {
    margin: 10px;
    font-size: 16px;
    color: #F56C6C;
}
</style>
